import consumer from "./consumer"


$(document).ready(function() {

  const element = document.getElementById('room-torneo_update-id');
  const room_id_torneo_update = element ? element.getAttribute('data-room-id') : '';

  console.log('room-score-id: ' + room_id_torneo_update);



  if (room_id_torneo_update != '' ){
    consumer.subscriptions.create({channel: "TorneoUpdateChannel", room:room_id_torneo_update},{ 
      connected() {
        // Called when the subscription is ready for use on the server
        console.log("Conectado al TorneoUpdateChannel: " + room_id_torneo_update)
        
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        var datajson = JSON.parse(data);
        console.log("datajson: " + datajson["data"])
        if (datajson["data"] == "refresh"){
          //Hacer clic en el link para actualizar la pagina (para que aparezca el reloj)
          document.getElementById("actualizar_pagina").click();
        }

      }
    });

  }


    
});
